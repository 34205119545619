import "./global.scss";
import React, {useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Index from "./pages";
import PaymentThankYou from "./pages/payment-thank-you";
import Payment from "./pages/payment";
import ReactGA from "react-ga4";
import TagManager from '@sooro-io/react-gtm-module'

const GA_TRACKING_ID = "G-1BY455LWGK";
const GTM_TRACKING_ID = "GTM-TJKD8FQ";

const tagManagerArgs = {
  gtmId: GTM_TRACKING_ID
}

ReactGA.initialize(GA_TRACKING_ID);
TagManager.initialize(tagManagerArgs)

export default function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    TagManager.dataLayer({dataLayer: { event: "pageview", page: window.location.pathname + window.location.search } });
    // console.log("pageview", window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />}/>
        <Route path="/payment-thank-you" element={<PaymentThankYou />}/>
        <Route path="/payment" element={<Payment />}/>
        {/* catch all redirect: */}
        <Route path="*" element={<Navigate to="/"/>}/>
      </Routes>
    </BrowserRouter>
  );
}