import React from "react";
import styled from "styled-components";

interface AppSupportArticleProps {
  articleId: string;
  title: string;
}

const AppSupportArticle: React.FC<AppSupportArticleProps> = (props: AppSupportArticleProps) => {
  return (
    <Bar>
      <a data-elevio-article={props.articleId} style={{ color: "#0494CA" }}>
        <p>
          {props.title}
        </p>
      </a>
    </Bar>
  )
};

const BAR_LENGTH = "80%";
const Bar = styled.div`
  width: ${BAR_LENGTH};
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0px;
  }

  @media (max-width: 768px) {
    width:500px;
  }

  @media (max-width: 621px) {
    width:450px;
  }
  @media (max-width: 535px) {
    width:400px;
  }

  @media (max-width: 468px) {
    width:350px;
  }

  @media (max-width: 390px) {
    width: 300px;
  }
`;
export default AppSupportArticle;