import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import styles from "./css/Footer.module.scss";

const Footer: React.FC = () => {

  return (
    <div className={styles.navContainer}>
      <div className="container">
        <div className={styles.navWrapper}>
          <div className={styles.navLogo}>
            <img src="/images/phonebox-logo.png" width="152px" alt="" />
          </div>
          <div className={styles.navTabsContainer}>
            <div className={styles.navTabItem}>
              <div
                onClick={() => window.open("https://www.gophonebox.com/about-us")}
                className={styles.navTitle}
              >
                About Us
              </div>
              <div
                onClick={() => window.open("https://www.gophonebox.com/terms-of-service")}
                className={styles.navTitle}
              >
                Terms of Service
              </div>
              <div
                onClick={() => window.open(`https://www.gophonebox.com/terms-of-service/?section=early-cancellation`)}
                className={styles.navTitle}
              >
                Return & Refund
              </div>
              {/* COVERAGE MAP */}
              <div
                onClick={() => window.open("https://www.gophonebox.com/coverage-map")}
                className={styles.navTitle}
              >
                Coverage Map
              </div>
              <div
                onClick={() => {
                  // window.localStorage.setItem("isContactUsQuery", 'true');
                  window.open("https://www.gophonebox.com/support");
                }}
                className={styles.navTitle}
              >
                Contact us
              </div>
              <div
                onClick={() => window.open("https://www.gophonebox.com/careers")}
                className={styles.navTitle}
              >
                Careers
              </div>
            </div>
            <div className={styles.navTabItem}>
              <div className={styles.navTitle}>
                Social Media
              </div>
              <div className={styles.navSocialLinks}>
                <div
                  onClick={() => window.open("https://www.facebook.com/gophonebox")}
                  className={styles.navSocialLink}
                >
                  <FontAwesomeIcon icon={faFacebook} /> Facebook
                </div>
                <div
                  onClick={() => window.open("https://twitter.com/gophonebox")}
                  className={styles.navSocialLink}
                >
                  <FontAwesomeIcon icon={faTwitter} /> Twitter
                </div>
                <div
                  onClick={() =>
                    window.open("https://www.instagram.com/gophonebox/")
                  }
                  className={styles.navSocialLink}
                >
                  <FontAwesomeIcon icon={faInstagram} />{" "}
                  Instagram
                </div>
              </div>
            </div>
            <div className={styles.navTabItem}>
              <div
                onClick={() => window.open("http://blog.gophonebox.com/")}
                className={styles.navTitle}
              >
                Blog
              </div>
              <div className={styles.navLinks}>
                <div
                  onClick={() => window.open("http://blog.gophonebox.com/")}
                  className={styles.navLink}
                >
                  All PhoneBox activities in one site
                </div>
              </div>
            </div>
            <div className={styles.navTabItem}>
              <div className={styles.navTitle}>
                Help
              </div>
              <div className={styles.navLinks}>
                <div
                  onClick={() =>
                    window.open("https://myaccount.gophonebox.com/login")
                  }
                  className={styles.navLink}
                >
                  Pay Your Bill
                </div>
                <div
                  onClick={() => window.open("https://www.gophonebox.com/activation")}
                  className={styles.navLink}
                >
                  Activate Your Plan
                </div>
                <div
                  onClick={() => window.open("https://www.gophonebox.com/support")}
                  className={styles.navLink}
                >
                  FAQs
                </div>
              </div>
            </div>
            <div className={styles.navAppStore}>
              <div className={styles.navTitle}>
                Download Our App!
              </div>
              <div className={styles.navAppWrapper}>
                <div className={styles.navAppInfo}>
                  <div className={styles.navAppDescription}>
                    <div>
                      Manage your Account & Services with ease from anywhere in
                      the world.
                    </div>
                    <br />
                    <div>All from one convenient location.</div>
                  </div>
                </div>
                <div style={{ width: 60, marginBottom: -10, color: "#fff" }}>
                  Canada:
                </div>
                <div className={styles.navAppLogos}>
                  <div
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.phonebox2021"
                      )
                    }
                  >
                    <img
                      src="/images/google-play-logo.png"
                    />
                  </div>
                  <div
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/ca/app/phonebox/id1566434192"
                      )
                    }
                  >
                    <img src="/images/app-store.png" />
                  </div>
                </div>
                <div style={{ width: 60, marginBottom: -10, color: "#fff" }}>
                  USA:
                </div>
                <div className={styles.navAppLogos}>
                  <div
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.phoneboxexpo.phoneboxfrontend"
                      )
                    }
                  >
                    <img
                      src="/images/google-play-logo.png"
                    />
                  </div>
                  <div
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/phonebox-us/id6446149914?platform=iphone"
                      )
                    }
                  >
                    <img src="/images/app-store.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerYear}>
            © {new Date().getFullYear()}{" "}
            Connex Global Communications Inc. o/a PhoneBox.  All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;