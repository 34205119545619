import React, { useEffect, useState } from "react";
import { api } from "../utilities/api";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface CountriesDropDownListProps {
  defaultValue: string;
  onSelect: Function;
  onlyUSA?: boolean; 
}

interface CountryType {
  CountryCodeISO: string,
  CountryName: string,
  CountryCode: number
}

const CountriesDropDownList: React.FC<CountriesDropDownListProps> = (props: CountriesDropDownListProps) => {
  let activationInfo = useSelector((state: RootState) => state.activationInfo);
  const isUSA = (name: string) => name === "USA" ? "United States" : name;
  const [countryObjects, setCountryObjects] = useState(activationInfo.countries);
  const [country, setCountry] = useState(isUSA(props.defaultValue));
  const [search, setSearch] = useState('');
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDropdownHeight(window.innerHeight - 350);

      const handleWindowResize = () => {
        if (window.innerWidth < 769) {
          setDropdownHeight(window.innerHeight - 500);
        } else {
          setDropdownHeight(window.innerHeight - 350);
        }
      };

      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);

      return () => window.removeEventListener("resize", handleWindowResize);
    }
  }, []);
  
  useEffect(() => {
    setCountry(isUSA(props.defaultValue));
  }, [props.defaultValue]);

  const optionSelect = (name: string) => {
    const countryName = isUSA(name);
    // @ts-ignore
    const countryIndex = countryObjects.find((c: CountryType) => c.CountryName === countryName)?.CountryCode;
    // @ts-ignore
    const countryCodeIso = countryObjects.find((c: CountryType) => c.CountryName === countryName)?.CountryCodeISO;
    props.onSelect({ countryName, countryIndex, countryCodeIso });
    setCountry(countryName);
  };

  const onClick = () => {
    if (search) {
      setSearch('');
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="w-100" disabled={props.onlyUSA}>
        {country}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{zIndex: 1}}>
        <InputGroup className="px-2">
          <Form.Control placeholder="Search Country..." type="text" value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control>
          <Button onClick={onClick}>
            <FontAwesomeIcon icon={search ? faClose : faSearch} />
          </Button>
        </InputGroup>
        <Dropdown.Divider />
        <div style={{ height: `${dropdownHeight}px`, overflow: 'auto'}}>
          {countryObjects.filter((s: any) => s.CountryName.toLowerCase().includes(search)).map((c: any, i: number) => (
            <Dropdown.Item key={i} onClick={() => optionSelect(c.CountryName)}>
              {c.CountryName}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CountriesDropDownList;