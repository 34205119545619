import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";

interface CustomPopupProps {
  onClose: Function;
  show: boolean;
  title: string;
  widthPct: number;
}

const CustomPopup: React.FC<PropsWithChildren<CustomPopupProps>> = (props) => {
  const [show, setShow] = useState(false);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <CustomPopupOverlay $show={show}>
      <CustomPopupPopup $widthPct={props.widthPct}>
        <CustomPopupTitle>
          {props.title}
        </CustomPopupTitle>
        <CustomPopupClose onClick={closeHandler}>
          &times;
        </CustomPopupClose>
        <CustomPopupContent>{props.children}</CustomPopupContent>
      </CustomPopupPopup>
    </CustomPopupOverlay>
  );
};

interface CustomPopupStyleProps {
  $show: boolean;
}

interface CustomPopupModalProps {
  $widthPct: number;
}

const CustomPopupClose = styled.span`
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
`;

const CustomPopupOverlay = styled.div<CustomPopupStyleProps>`
  z-index: 10;
  visibility: ${props => props.$show ? 'visible' : 'hidden'};
  opacity: ${props => props.$show ? '1' : '0'};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
`;

const CustomPopupPopup = styled.div<CustomPopupModalProps>`
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: ${props => props.$widthPct}%;
  position: relative;
  transition: all 5s ease-in-out;
  
  &:hover ${CustomPopupClose} {
    cursor: pointer;
    color: #000;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;

const CustomPopupTitle = styled.h2`
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
`;

const CustomPopupContent = styled.div`
  max-height: 30%;
  overflow: auto;
`;

export default CustomPopup;
