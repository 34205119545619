import { DataRow } from "../types/models/DataRow";
import { PaymentMethodType } from "../types/enums/PaymentMethodType";
import { PAYMENT_METHOD_ALIPAY_QR, PAYMENT_METHOD_APPLE_PAY, PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_PAYPAL, PAYMENT_METHOD_WX_NATIVE, constants } from "./constants";
import { api } from "./api";
import { updateActivationInfo } from "../redux/actions/activationInfoActions";
import { updateLocal } from "../redux/actions/localActions";

/**
 * Maps plan data from phonebox API only.
 * @param data 
 * @param planType 
 * @returns 
 */
export const mapBoxOnePlans = (data: any, planType: string): DataRow[] => {
  const isPostPaid = planType === constants.POSTPAID_PLAN;
  let plans: DataRow[] = data.map((e: any) => {
      return {
          planId: isPostPaid ? e.pfId : e.PlanId,
          plan_fee: isPostPaid ? e.pfAmt : e.Fee,
          carrier: e.telusplan ? 'Telus' : e.Carriers ? getCarrierFullName(e.Carriers) : getCarrierFullName(''),
          pfCode: isPostPaid ? e.pfCode : e.PlanCode,
          data: isPostPaid
              ? formatPhoneData(e.dataCap.split('.')[0], e.PlanTypeD)
              : formatPhoneData((e.Data / (1024 * 1024)).toString(), e.PlanTypeD),
          planTypeD: isPostPaid ? "" : e.PlanTypeD,
          calling: e.Calling ? e.Calling : null,  // Applies for data-only plans.  "N/A" means data only.
          message: e.Message ? e.Message : null,  // Applies for data-only plans.  "N/A" means data only.
          plintronPlanId: null, // Applies to Portaone Plans only. Doesn't apply to BoxOne plans but we need to maintain the plans structure to avoid undefined errors
          subscriptionId: null, // Applies to Portaone Plans only. Doesn't apply to BoxOne plans but we need to maintain the plans structure to avoid undefined errors
      } as DataRow;
  });
  return plans;
}

/**
 * Shows data.
 * @param data 
 * @returns 
 */
export const formatPhoneData = (data: string | number, planTypeD: string | number) => {
  if (data === "Voice" || Number(data) === 0 || data === "Voice Only") {
      return "Voice";
  }
  if (Number(data) > 9000 || data === "Unlimited") {
      return "Unlimited";
  }
  if (typeof data === 'string' && (data.indexOf('MB') !== -1 || data.indexOf('GB') !== -1)) {
      return data;
  }
  if (Number(data) > 99 && Number(planTypeD) === 90) {
      return data + " GB";
  } 
  if (Number(data) > 99 && !planTypeD) {
      return data + " GB";
  }
  if (Number(data) > 99) {
      return data + " MB";
  }
  return data + " GB";
};

/**
 * Gets carrier full name.
 * @param short 
 * @returns 
 */
export const getCarrierFullName = (short: string) => {
  if (short === "T") {
      return "Telus";
  } else {
      return "Rogers";
  }
}

/**
 * Gets country ID from country string
 * @param country 
 * @returns countryId
 */
export const getServiceCountryId = (country: string) => {
  switch (country) {
    case constants.USA:
      return 247;
    default:
      return 42;
  }
};

/**
 * Validates an email for Portaone. Portaone self server web care login only allows certain characters. The rules for this are here: The value must contain only latin letters, digits, '-', '_', '@', and '.'
 * @param email 
 * @returns 
 */
export const validateEmail = (email: string): boolean => {
  // Regular expression pattern to match the email address format
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Match the email address against the pattern
  const match = pattern.test(email);

  // If the match is successful, the email address is valid
  return match;
}

/**
 * Returns strings with only numbers in it.
 * @param value 
 * @returns 
 */
export const numbersOnly = (value: string) => value.replace(/\D/g, "");


/**
 * Maps string constant of payment method to enumeration used in Proxy API.
 * @param paymentMethod 
 * @returns 
 */
export const getPaymentMethodType = (paymentMethod: string): PaymentMethodType | undefined => {
  switch (paymentMethod) {
      case PAYMENT_METHOD_CREDIT_CARD:
          return PaymentMethodType.CreditCard;
      case PAYMENT_METHOD_PAYPAL:
          return PaymentMethodType.PayPal;
      case PAYMENT_METHOD_ALIPAY_QR:
          return PaymentMethodType.AliPayQR;
      case PAYMENT_METHOD_APPLE_PAY:
          return PaymentMethodType.ApplePay;
      case PAYMENT_METHOD_WX_NATIVE:
          return PaymentMethodType.WxNative;
  }
}

/**
* Sends a request to the specified url from a form. This will change the window location.
* @param {string} path the path to send the post request to
* @param {object} params the parameters to add to the url
* @param {string} [method=post] the method to use on the form
*/

export const submitAsForm = (path: string, params: any, method = 'post') => {

  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;
  for (const key in params) {
      if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
      }
  }
  document.body.appendChild(form);
  form.submit();
}

/**
 * Gets the partner id (for affiliates) from the URL parameters of the page location.
 * @param location
 * @returns
 */
export const getPartnerIdFromPageLocation = (location: any): { canadaPartnerId: number, usPartnerId: number } => {
  var url = new URL(location.href);
  let canadaPartnerId = url.searchParams.get('bn');
  const usPartnerId = url.searchParams.get('i_rep');
  // check local storage for rbc affiliate link
  let hasRbcLink = sessionStorage.getItem("raLink");
  if (hasRbcLink === "true" && !canadaPartnerId) {
    canadaPartnerId = "4036";
  }

  return {
      canadaPartnerId: !!canadaPartnerId ? +canadaPartnerId : 0,
      usPartnerId: !!usPartnerId ? +usPartnerId : 0
  };
}

/**
 * Gets the Canadian plans as determined by the partner ID.
 * @param canadaPartnerId 
 * @param dispatch 
 */
export const getCanadaPlans = (dispatch: Function, canadaPartnerId: number) => {
  // this is just for the "getPartnerPromoCodeAll" functions
  dispatch(updateActivationInfo({ usPromocode: "" }) as any);

  if (canadaPartnerId) {
    // first, put the bizId into the activationInfo
    dispatch(updateActivationInfo({ bizId: canadaPartnerId }) as any)

    api.getPartnerPromoCodeAll(canadaPartnerId)
      .then(res => {
        if (res && res.data) {
          const prepaidPromoCode = res.data.CANPrepaid ? res.data.CANPrepaid : "";
          const postpaidPromoCode = res.data.CANPostpaid ? res.data.CANPostpaid : "";

          console.log("BN: ", canadaPartnerId)
          console.log("Prepaid PromoCode: ", prepaidPromoCode)

          if (res.data.USAPrepaid && res.data.USAPrepaid === "SimcardNoCharge") {
            dispatch(updateActivationInfo({ usSimCardNoCharge: true }) as any);
            dispatch(updateActivationInfo({ usPromocode: res.data.USAPrepaid }) as any);
          } else {
            dispatch(updateActivationInfo({ usSimCardNoCharge: false }) as any);
            dispatch(updateActivationInfo({ usPromocode: "" }) as any);
          }

          const planApiCalls = [];
          // if prepaid promo code found at query to get by prepaid promo code
          if (prepaidPromoCode) {
            planApiCalls.push(api.getPlans("", prepaidPromoCode, constants.CANADA_CURRENCY, constants.PREPAID_PLAN));
            dispatch(updateActivationInfo({ prepaidPromocode: prepaidPromoCode }) as any);
          } 

          // if postpaid promo code found add query to get by postpaid promo code
          if (postpaidPromoCode) {
            planApiCalls.push(api.getPlans("", postpaidPromoCode, constants.CANADA_CURRENCY, constants.POSTPAID_PLAN));
            dispatch(updateActivationInfo({ promocode: postpaidPromoCode }));
          }
          
          if (!prepaidPromoCode && !postpaidPromoCode) {
            planApiCalls.push(api.getPlans("", "", constants.CANADA_CURRENCY, constants.PREPAID_PLAN));
            planApiCalls.push(api.getPlans("", "", constants.CANADA_CURRENCY, constants.POSTPAID_PLAN));
            dispatch(updateActivationInfo({ promocode: "" }) as any);
            dispatch(updateActivationInfo({ prepaidPromocode: "" }) as any);
          }

          Promise.all(planApiCalls)
            .then((results) => {
              let canadaPrepaidResults = [] as DataRow[];
              let canadaPostpaidResults = [] as DataRow[];
              switch (planApiCalls.length) {
                // if length is 2 the order will always be prepaid then postpaid regardless of
                // whether its default plans or plans with promo codes
                case 2:
                  canadaPrepaidResults = mapBoxOnePlans(results[0].data, constants.PREPAID_PLAN);
                  canadaPostpaidResults = mapBoxOnePlans(results[1].data, constants.POSTPAID_PLAN);
                  break;

                // if length is 1 and prepaid promo code is there then we're retrieving prepaid plans from first result set and setting postpaid to empty
                // if length is 1 and postpaid promo code is there then we're retrieving postpaid plans from first result set and setting prepaid to empty
                case 1:
                  canadaPrepaidResults = prepaidPromoCode ? mapBoxOnePlans(results[0].data, constants.PREPAID_PLAN) : [];
                  canadaPostpaidResults = postpaidPromoCode ? mapBoxOnePlans(results[0].data, constants.POSTPAID_PLAN) : [];
                  break;
              }

              console.log("pre  received from promocode: ", canadaPrepaidResults);
              console.log("post received from promocode: ", canadaPostpaidResults);

              dispatch(updateLocal({ canadaPlansPrepaid: canadaPrepaidResults }) as any);
              dispatch(updateLocal({ canadaPlansPostpaid: canadaPostpaidResults }) as any);
            })
            .catch(err => {
              console.error(err);
            });
        }
      }).catch((error) => {
        console.error(error);
      });
  } else {
    // default
    const planApiCalls = [
      api.getPlans("", "", constants.CANADA_CURRENCY, constants.PREPAID_PLAN),
      api.getPlans("", "", constants.CANADA_CURRENCY, constants.POSTPAID_PLAN),
    ];

    Promise.all(planApiCalls).then((results) => {
      let canadaPrepaidResults = mapBoxOnePlans(results[0].data, constants.PREPAID_PLAN);
      let canadaPostpaidResults = mapBoxOnePlans(results[1].data, constants.POSTPAID_PLAN);

      dispatch(updateLocal({ canadaPlansPrepaid: canadaPrepaidResults }) as any);
      dispatch(updateLocal({ canadaPlansPostpaid: canadaPostpaidResults }) as any);
    });
  }
}