import axios from "axios";

const window = global.window;
let baseURL;

if (typeof window === "undefined") {
  baseURL = "https://phoneboxapi.azurewebsites.net/api/"
} else if (window.location.href.includes("azurestaticapps") || window.location.href.includes("localhost") || window.location.href.includes("test-myesim")) {
  baseURL = "https://testmyaccount.azurewebsites.net/api/"
} else {
  baseURL = "https://phoneboxapi.azurewebsites.net/api/"
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 8000,
  headers: {},
});

instance.interceptors.request.use(async (config) => {
  try {
    config.headers["client"] = "eSim";
    config.headers["apitoken"] = process.env.REACT_APP_BOX_ONE_API_TOKEN;
    return config;
  } catch (e) {
    console.error(e);
  }
});

export default instance;
