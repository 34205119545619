export enum Step {
  Error = 0,
  First = 1,
  Second = 2,
  Third = 3,
}

export namespace Step {
  export function after(value: Step): Step {
      return value + 1;
  }

  export function previous(value: Step): Step {
    return value - 1;
  }
}