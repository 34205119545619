import instance from "./proxy-axios.js";
import { ApiResponse } from "../types/models/ApiResponse.js";
import { ApiRequest } from "../types/models/ApiRequest.js";

export const proxyApi = {
    account: {
      getAccountList: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/p1/Account/get_account_list`, request),
    },
    checkout: {
      checkout: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Checkout`, request),
      prepaidTotal: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Checkout/prepaid_total`, request),
      completeCheckout: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Checkout/complete`, request),
    },
    plan: {
      getDefaultPlans: (): Promise<ApiResponse> => instance.post(`/Plans/get_default_plans`),
      getPartnerPlans: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Plans/get_partner_plans`, request),
    },
    plintron: {
      queryDevice: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Plintron/query_device`, request),
      extMnpCheckPortinEligibility: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Plintron/ext_mnp_check_portin_eligibility`, request),
    },
    subscription: {
      getSubscriptionList: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/p1/Subscription/get_subscription_list`, request)
    },
    transaction: {
      payPal: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Transaction/pay_pal`, request),
      creditCard: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Transaction/credit_card`, request),
    }
};