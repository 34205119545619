import axios from "axios";
import React, { useState, useEffect, BaseSyntheticEvent } from "react";
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';

interface CPAutoCompleteAddressProps {
    // autoCompleteType: string;
    onAutoCompleteAddress: Function;
    selectedCountry: string;
}

interface Address {
    street: string;
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    provinceShort: string;
    countryShort: string;
    provinceFull: string;
    countryFull: string;
}

const CanadaPostAutoCompleteAddress: React.FC<CPAutoCompleteAddressProps> = (props: CPAutoCompleteAddressProps) => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([] as any[]);
    const [locations, setLocations] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);

    const apiFindUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${process.env.REACT_APP_CANADA_POST_API_KEY}`;
    const apiRetrieveUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${process.env.REACT_APP_CANADA_POST_API_KEY}`;

    const handleSelect = async (event: any) => {
        if (event[0].next === "Find") {
            let selectedEvent = event[0]
            setIsLoading(true);
            handleSearchLastId(selectedEvent.id, selectedEvent.addressCount);   
        }
        else if (event[0].next === "Retrieve") {
            handleRetrieve(event);
        }
    }

    const handleRetrieve = async (event: any) => {
        let selectedEventId = event[0].id;
        let url = `${apiRetrieveUrl}&Id=${encodeURIComponent(selectedEventId)}`;

        try {
            const response = await fetch(url);
            const jsonData = await response.json();
            const data = jsonData.Items[0];

            if (data) {
                let address: Address = {
                    street: data.Line1 + (data.Line2 ? ', ' + data.Line2 : ''),
                    line1: data.Line1,
                    line2: data.Line2,
                    city: data.City,
                    postalCode: data.PostalCode,
                    provinceShort: data.Province,
                    countryShort: data.CountryIso2,
                    provinceFull: data.ProvinceName,
                    countryFull: data.CountryName
                };
                sendCompleteAddress(address);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const sendCompleteAddress = (address: Address) => {
        let addressArray = [address.street, address.city, address.provinceShort, address.countryShort, address.postalCode, address.countryFull, address.provinceFull];
        props.onAutoCompleteAddress(addressArray);
    }

    const handleChange = (event: any) => {
        setIsLoading(true);
        setOpenDropdown(true);
        setQuery(event);
        handleSearchTerm(event);
    }

    const handleSearchTerm = async (query: any) => {
        let url = `${apiFindUrl}&SearchTerm=${encodeURIComponent(query)}&Country=${encodeURIComponent(props.selectedCountry)}&MaxResults=${encodeURIComponent(8)}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            setResults(data.Items);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearchLastId = async (id: any, retrieveMaximum: any) => {
        let url = `${apiFindUrl}&LastId=${encodeURIComponent(id)}&MaxResults=${encodeURIComponent(retrieveMaximum)}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            let newResults = data.Items;
            setResults(newResults);
        
            let tempLocations = newResults.map((result: any) => {
                let maxAddressCount = 10;
                return {
                    id: result.Id,
                    name: result.Text + ", " + result.Description,
                    next: result.Next,
                    addressCount: maxAddressCount,
                }
            });
            setLocations(tempLocations);
            setIsLoading(false);
            setOpenDropdown(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let tempLocations = results.map((result) => {
            const parts = result.Description.split('-');
            let maxAddressCount = 10;
            if (parts.length > 1) {
                const addressCountString = parts[1].trim().split(' ')[0];
                maxAddressCount = parseInt(addressCountString);
            }
    
            return {
                id: result.Id,
                name: result.Text + ", " + result.Description,
                next: result.Next,
                addressCount: maxAddressCount,
            }
        });
        setLocations(tempLocations);
        setIsLoading(false);
        if (results.length > 0){
            setOpenDropdown(true);
        }
    }, [results]);

    return (
        <div className="search-location-input">
            <AsyncTypeahead
                filterBy={() => true}
                id="basic-typeahead-single"
                labelKey="name"
                isLoading={isLoading}
                minLength={2}
                onSearch={(query) => handleChange(query)}
                onChange={(event) => handleSelect(event)}
                options={locations}
                open={openDropdown}
                placeholder="Enter your address"
                onBlur={() => setOpenDropdown(false)}
                renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      {results.map((result: any, index) => (
                        <MenuItem
                          onClick={() => setOpenDropdown(!openDropdown)}
                          option={result}
                          position={index}>
                            {result.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
            />
        </div>
    );
}

export default CanadaPostAutoCompleteAddress;