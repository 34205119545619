export const constants = {
  // Countries
  CANADA: "Canada",
  USA:"USA",
  
  // Currencies
  CANADA_CURRENCY: "CAD",
  USA_CURRENCY: "USD",

  // payment plan
  PREPAID_PLAN: "Prepaid",
  POSTPAID_PLAN: "Postpaid",
  DATA_PLAN:"DATA",
  DATA_ONLY_PLAN: "Data",
  WEEKLY_PLAN: "Weekly",
  BIWEEKLY_PLAN: "Biweekly",
  NINETY_DAYS_PLAN: "90 Days"
}

export const colors = {
  BLUE: "#0494CA",
  GREEN: "#82C36D",
  RED: "#E93F25",
  LIGHTBLUE: "#ECFAFF",
  LIGHTGREEN: "#E3FADA",
  TRANSPARENTBLUE: "rgba(4, 148, 202, 0.2)",
  TRANSPARENTGREEN: "rgba(130, 195, 109, 0.2)"
}

export const provinces = [
  { key: 1, name: 'Alberta', code: 'AB' },
  { key: 2, name: 'British Columbia', code: 'BC' },
  { key: 3, name: 'Manitoba', code: 'MB' },
  { key: 4, name: 'New Brunswick', code: 'NB' },
  { key: 5, name: 'Newfoundland and Labrador', code: 'NL' },
  { key: 6, name: 'Northwest Territories', code: 'NT' },
  { key: 7, name: 'Nova Scotia', code: 'NS' },
  { key: 8, name: 'Nunavut', code: 'NU' },
  { key: 9, name: 'Ontario', code: 'ON' },
  { key: 10, name: 'Prince Edward Island', code: 'PE' },
  // { key: 11, name: 'Quebec', code: 'QC' },
  { key: 12, name: 'Saskatchewan', code: 'SK' },
  { key: 13, name: 'Yukon Territory', code: 'YT' }
];

export const carriers = [
  { key: 1, name: 'Bell' },
  { key: 2, name: 'Fido' },
  { key: 3, name: 'Rogers' },
  { key: 4, name: 'Telus' },
  { key: 5, name: 'Koodo' },
  { key: 6, name: 'Public Mobile' },
  { key: 7, name: 'Chatr' },
  { key: 8, name: 'Lucky Mobile' },
  { key: 9, name: 'Virgin' },
  { key: 10, name: 'Freedom' },
  { key: 11, name: 'Shaw' },
  { key: 12, name: 'CTExcel' },
  { key: 13, name: 'Others' },
];

export const states = [
  { key: 14, name: 'Delaware', code: 'DE' },
  { key: 15, name: 'Washington', code: 'WA' },
  { key: 16, name: 'California', code: 'CA' },
  { key: 17, name: 'Massachusetts', code: 'MA' },
  { key: 18, name: 'Texas', code: 'TX' },
  { key: 19, name: 'Florida', code: 'FL' },
  { key: 20, name: 'New York', code: 'NY' },
  { key: 21, name: 'I\'m not sure', code: 'DE' },
]

export const PAYMENT_METHOD_PAYPAL = "paypal";
export const PAYMENT_METHOD_CREDIT_CARD = "creditcard";
export const PAYMENT_METHOD_ALIPAY_QR = "alipay_qr";
export const PAYMENT_METHOD_WX_NATIVE = "wx_native";
export const PAYMENT_METHOD_APPLE_PAY = "applePay";
export const PAYMENT_METHOD_GOOGLE_PAY = "googlePay";

export const NO_US_PLANS_PARTNERS = [
  4433
]

  // Quebec area codes to disallow for port-in
export const QUEBEC_AREA_CODES = [
  "263",
  "354",
  "367",
  "418",
  "438",
  "450",
  "468",
  "514",
  "579",
  "581",
  "819",
  "873"
];

// carriers that cannot have portin for Telus plan
export const INVALID_TELUS_CARRIERS = [
  'Koodo',
  'Telus',
  'Public Mobile',
  'CTExcel'
];