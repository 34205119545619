import React, { useState } from "react";
import styled from "styled-components";

interface SwitchButtonProps {
  width?: number;
  height?: number;
  imgOn?: any;
  imgOff?: any;
  textOn?: string;
  textOff?: string;
  onSwitch?: Function;
  defaultState?: boolean;
}

const SwitchButton: React.FC<SwitchButtonProps> = (props: SwitchButtonProps) => {
  const [buttonState, setButtonState] = useState(
    props.defaultState ? true : false
  );
  const onClick = () => {
    props.onSwitch && props.onSwitch();
    setButtonState(!buttonState);
  };

  const propsHeight = props.height ? props.height : 100;

  const FONTSIZE = `${propsHeight / 4}px`;

  return (
    <Layout width={props.width} height={props.height} onClick={onClick}>
      {buttonState || (
        <TextArea fontSize={FONTSIZE}>
          {props.textOff ? props.textOff : "Off"}
        </TextArea>
      )}
      <SweepButton
        width={propsHeight * 0.8}
        background={buttonState ? props.imgOn : props.imgOff}
        $buttonState
      />
      {buttonState && (
        <TextArea fontSize={FONTSIZE}>
          {props.textOn ? props.textOn : "On"}
        </TextArea>
      )}
    </Layout>
  );
};

const Layout = styled.div<{ width?: number; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  padding: 2% 1%;
  border-radius: 80px;
  width: ${(props) => (props.width ? props.width : "80")}px;
  height: ${(props) => (props.height ? props.height : "50")}px;
  background-color: rgba(200, 200, 200, 0.5);
`;

const SweepButton = styled.div<{
  width?: number;
  $buttonState?: boolean;
  background?: any;
}>`
  border-radius: 360px;
  width: ${(props) => (props.width ? props.width : "40")}px;
  height: ${(props) => (props.width ? props.width : "40")}px;
  background: ${(props) => (props.$buttonState ? "#0594ca" : "gray")}
    url(${(props) => props.background}) no-repeat;
  background-size: cover;
`;

const TextArea = styled.div<{
  fontSize?: string;
}>`
  padding: 5px;
  font-size: ${(props) => props.fontSize};
  font-weight: 500;
  color: gray;
  text-align: center;
  user-select: none;
`;
export default SwitchButton;
